const constants = {
  // User Roles
  USER_ROLE_ADMIN: 'ROLE_ADMINISTRATOR',
  USER_ROLE_UNDERWRITER: 'ROLE_UNDERWRITER',
  USER_ROLE_UNDERWRITERADMIN: 'ROLE_UNDERWRITERADMIN',
  USER_ROLE_PORTFOLIOMANAGER: 'ROLE_PORTFOLIOMANAGER',
  USER_ROLE_PREMIUMUSER: 'ROLE_PREMIUMUSER',
  USER_ROLE_ACTUARY: 'ROLE_ACTUARY',
  USER_ROLE_CUSTOMER: 'ROLE_CUSTOMER',
  USER_ROLE_UNDERWRITER2: 'ROLE_UNDERWRITER2',
  // Better items exist in appVariables.js.
  // TODO: Remove all these constants to replace with appVariables.
  ROLE_PORTFOLIOMANAGER: 'Portfolio Manager',
  ROLE_PREMIUM_USER: 'Premium User',
  USER_ADDON_SPOFINTELLIGENCE: 'SPOF',

  // Home pages
  HOME_PAGE_ADMIN: 'admin',
  HOME_PAGE_UNDERWRITER: 'global_search',
  HOME_PAGE_UNDERWRITERADMIN: 'portfolio',
  HOME_PAGE_PORTFOLIOMANAGER: 'portfolio',
  HOME_PAGE_ACTUARY: 'actuary',
  HOME_PAGE_CUSTOMER: 'questionnaire',
  HOME_PAGE_UNDERWRITER2: 'portfolio',

  // Portfolio Permissions
  EDIT_PERMISSION: 'EDIT',
  VIEW_PERMISSION: 'VIEW',
  ADMIN_PERMISSION: 'ADMIN',

  // Default Base URL for Breach Module. It will be used as fallback if API returns error
  DEFAULT_BREACH_URL: 'https://localhost:8482',

  // Application Status
  APPLICATION_IN_PROGRESS: 'InProgress',
  APPLICATION_CREATED: 'Created',
  APPLICATION_REJECTED: 'Rejected',
  APPLICATION_APPROVED: 'Approved',

  // Graphs
  MALWARE_TARGETED_ATTACKS: 'malware-targetedAttacks',
  PHISHING_TARGETED_ATTACKS: 'phishing-targetedAttacks',
  TARGETED_ATTACKS: 'targetedAttacks',
  HYGIENE: 'hygiene',
  REMEDIATION_EFFICIENCY: 'remediationEfficiency',
  BLOCKING_EFFECTIVENESS: 'blockingEffectiveness',
  VULNERABILITIES: 'vulnerabilities',
  HACKTOOLS: 'hacktools',
  WEBBASEDATTACKS: 'webBasedAttacks',
  TOTAL_BREACHES: 'totalBreaches',
  GRAPH_LINE: 'graphline',
  GRAPH_STACK_BAR: 'graphstackbar',
  GRAPH_DONUT: 'graphdonut',
  WEB_BASED_ATTACKS: 'webBasedAttacks',
  WEB_BASED_ATTACKS_TOTAL: 'webBasedAttacks_total',
  VENDOR_RISK: 'vendorRisk',

  ABOVE_INDUSTRY: 'Above Industry',
  BELOW_INDUSTRY: 'Below Industry',
  ABOVE_MARKET: 'Above Market',
  BELOW_MARKET: 'Below Market',
  INDUSTRY_STANDARD: 'Industry Standard',
  MARKET_STANDARD: 'Market Standard',
  ABOVE: 'Above',
  BELOW: 'Below',
  NOT_APPLICABLE: 'N/A',

  //Field Validation
  ALPHANUMERIC: 'alphanumeric',
  NUMERIC: 'numeric',
  ALPHANUMERIC_MESSAGE: 'No special characters. Remove trailing spaces.',
  NUMERIC_MESSAGE: 'Only numbers are allowed.',
  DOMAIN: 'domainname',
  DOMAIN_MESSAGE: 'Invalid domain name.',
  SIC_CODE: 'siccode',
  SIC_CODE_MESSAGE: 'Invalid SIC code.',
  DUPLICATE_ENTRY_MESSAGE: 'Duplicate entry not allowed.',

  //Answer Source
  COMPANY_NAME: 'companyName',
  INDUSTRY_ID: 'industryId',
  EMPLOYEE_COUNT: 'employeeCount',
  COUNTRY: 'country',

  //Input Components
  LIST: 'list',
  SELECT: 'select',
  CK_GROUP: 'ckgroup',

  // Operators
  GREATER_THAN: '>',
  LESS_THAN: '<',
  GREATER_THAN_EQUAL: '>=',
  LESS_THAN_EQUAL: '<=',

  PROGRESS_COMPLETE: '100',

  APPLICATIONS: {
    SCORING_REPORTS: 'Account Analysis Results',
    PORTFOLIOS: 'Portfolios',
    PORTFOLIO_REPORTS: 'Portfolio Analysis Results',
    SPOF: 'SPoF Metrics',
    PLATFORM_SETTINGS: 'Platform Settings',
    IMPORT_MGMT: 'Import Management',
    CMS: 'Documentation',
  },
};

export const APP_URL_PREFIX = {
  AM: '/am/',
  PM: '/pm/',
};

export const ROUTE_NAMES = {
  AM: {
    SCORING_REPORTS: 'scoring_reports',
    GLOBAL_SEARCH: 'global_search',
    SCORING_RESULT_COMPANY: 'scoring_result_company',
    COMPANY_SCORING_DETAILS: 'company_scoring_details',
    ANALYSIS_SCORING: 'analysis_scoring',
  },
  PM: {
    PLATFORM_SETTINGS: 'platform_settings',
    PORTFOLIOS: 'portfolios',
    PROBABILISTIC_ANALYSIS: 'probabilistic_analysis',
    SCENARIO_ANALYSIS: 'scenario_analysis',
    POLICY_WORDING: 'policy_wording',
    PORTFOLIO_DETAILS: 'portfolio_details',
    SPOF_METRICS: 'spof_metrics',
    IMPORT_MGMT: 'import_mgmt',
    ANALYSIS_RESULT: 'analysis_result',
    ANALYSIS_REPORTS: 'analysis_reports',
  },
  RESTRICTED: 'restricted',
  SCAN: 'scan',
};

export const ROUTES = {
  ANALYSIS_REPORTS: `${APP_URL_PREFIX.PM}${ROUTE_NAMES.PM.ANALYSIS_REPORTS}`,
  ANALYSIS_RESULT: `${APP_URL_PREFIX.PM}${ROUTE_NAMES.PM.ANALYSIS_RESULT}`,
  ANALYSIS_SCORING: `${APP_URL_PREFIX.AM}${ROUTE_NAMES.AM.ANALYSIS_SCORING}`,
  GLOBAL_SEARCH: `${APP_URL_PREFIX.AM}${ROUTE_NAMES.AM.GLOBAL_SEARCH}`,
  IMPORT_MGMT: `${APP_URL_PREFIX.PM}${ROUTE_NAMES.PM.IMPORT_MGMT}`,
  PLATFORM_SETTINGS: `${APP_URL_PREFIX.PM}${ROUTE_NAMES.PM.PLATFORM_SETTINGS}`,
  POLICY_WORDING: `${APP_URL_PREFIX.PM}${ROUTE_NAMES.PM.POLICY_WORDING}`,
  PORTFOLIO_DETAILS: `${APP_URL_PREFIX.PM}${ROUTE_NAMES.PM.PORTFOLIO_DETAILS}`,
  PORTFOLIOS: `${APP_URL_PREFIX.PM}${ROUTE_NAMES.PM.PORTFOLIOS}`,
  PROBABILISTIC_ANALYSIS: `${APP_URL_PREFIX.PM}${ROUTE_NAMES.PM.PROBABILISTIC_ANALYSIS}`,
  RESTRICTED: `/${ROUTE_NAMES.RESTRICTED}`,
  SCAN: `/${ROUTE_NAMES.SCAN}`,
  SCENARIO_ANALYSIS: `${APP_URL_PREFIX.PM}${ROUTE_NAMES.PM.SCENARIO_ANALYSIS}`,
  SCORING_REPORTS: `${APP_URL_PREFIX.AM}${ROUTE_NAMES.AM.SCORING_REPORTS}`,
  SCORING_RESULT_COMPANY: `${APP_URL_PREFIX.AM}${ROUTE_NAMES.AM.SCORING_RESULT_COMPANY}`,
  SPOF_METRICS: `${APP_URL_PREFIX.PM}${ROUTE_NAMES.PM.SPOF_METRICS}`,
};

export const URL_WILDCARD_SUFIX = '*';

export default constants;
