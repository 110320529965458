import constants, { ROUTES, URL_WILDCARD_SUFIX } from './constants';

export default [
  {
    name: 'Scoring Reports',
    url: ROUTES.SCORING_REPORTS,
    highlightUrlMatch: [
      ROUTES.GLOBAL_SEARCH,
      ROUTES.SCORING_REPORTS,
      ROUTES.SCORING_RESULT_COMPANY,
      '',
    ],
    allowRoles: [constants.USER_ROLE_PREMIUMUSER, constants.USER_ROLE_UNDERWRITER],
    icon: 'line-chart',
    application: constants.APPLICATIONS.SCORING_REPORTS,
  },
  {
    name: 'Portfolios',
    url: ROUTES.PORTFOLIOS,
    highlightUrlMatch: [
      ROUTES.PORTFOLIOS,
      ROUTES.PORTFOLIO_DETAILS,
      ROUTES.PROBABILISTIC_ANALYSIS,
      ROUTES.SCENARIO_ANALYSIS,
    ],
    allowRoles: [constants.USER_ROLE_PORTFOLIOMANAGER, constants.USER_ROLE_PREMIUMUSER],
    icon: 'briefcase',
    application: constants.APPLICATIONS.PORTFOLIOS,
  },
  {
    name: 'Analysis Reports',
    url: ROUTES.ANALYSIS_REPORTS,
    highlightUrlMatch: [
      ROUTES.ANALYSIS_REPORTS,
      '/analysis_result_legacy_view',
      ROUTES.ANALYSIS_RESULT,
      ROUTES.ANALYSIS_SCORING,
    ],
    allowRoles: [constants.USER_ROLE_PORTFOLIOMANAGER, constants.USER_ROLE_PREMIUMUSER],
    icon: 'bar-chart',
    application: constants.APPLICATIONS.PORTFOLIO_REPORTS,
  },
  {
    name: 'SPoF Metrics',
    url: ROUTES.SPOF_METRICS,
    highlightUrlMatch: [ROUTES.SPOF_METRICS + '/' + URL_WILDCARD_SUFIX],
    allowRoles: [constants.USER_ROLE_PORTFOLIOMANAGER, constants.USER_ROLE_PREMIUMUSER],
    addons: [constants.USER_ADDON_SPOFINTELLIGENCE],
    icon: 'spof',
    application: constants.APPLICATIONS.SPOF,
  },
  {
    name: 'Import Management',
    url: ROUTES.IMPORT_MGMT,
    highlightUrlMatch: [ROUTES.IMPORT_MGMT],
    allowRoles: [constants.USER_ROLE_PORTFOLIOMANAGER, constants.USER_ROLE_PREMIUMUSER],
    icon: 'import_mgmt',
    application: constants.APPLICATIONS.IMPORT_MGMT,
  },
  {
    name: 'Platform Settings',
    url: ROUTES.PLATFORM_SETTINGS,
    highlightUrlMatch: [ROUTES.PLATFORM_SETTINGS, ROUTES.POLICY_WORDING],
    allowRoles: [constants.USER_ROLE_PORTFOLIOMANAGER, constants.USER_ROLE_PREMIUMUSER],
    icon: 'gear',
    application: constants.APPLICATIONS.PLATFORM_SETTINGS,
  },
];
